import { createSlice } from '@reduxjs/toolkit';
export const csrfTokenSlice = createSlice({
  name: 'csrfToken',
  initialState: null,
  reducers: {
    setCsrfToken: (state, action) => action.payload,
  },
});

export const { setCsrfToken } = csrfTokenSlice.actions;
