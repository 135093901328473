import {combineReducers} from '@reduxjs/toolkit';
import navbarReducer from '@/slices/navbarSlice';
import { culinaryApi } from '@/slices/culinaryApi';
import { csrfTokenSlice } from '@/slices/csrfTokenSlice';
import {productAndDishApi} from "@/slices/productAndDishApi";

const rootReducer = combineReducers({
  navbar: navbarReducer,
  csrfToken: csrfTokenSlice.reducer,
  [culinaryApi.reducerPath]: culinaryApi.reducer,
  [productAndDishApi.reducerPath]: productAndDishApi.reducer
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;