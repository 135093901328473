import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '.';

export const initialState: NavbarState = {
  isOpen: true,
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    openNavbar: (state) => {
      state.isOpen = true
    },
    closeNavbar: (state) => {
      state.isOpen = false;
    },
  },
});

export const {
  openNavbar,
  closeNavbar
} = navbarSlice.actions;

export const navbarSelector = (state: RootState) => state.navbar;

// export The reducer
const navbarReducer = navbarSlice.reducer;
export default navbarReducer;

export type NavbarState = {
  isOpen: boolean;
};