import { culinaryApi } from '@/slices/culinaryApi';
import { productAndDishApi } from '@/slices/productAndDishApi';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rootReducer, { RootState } from '../slices';

const index = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(culinaryApi.middleware)
      .concat(productAndDishApi.middleware)
});

export type AppDispatch = typeof index.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default index;
