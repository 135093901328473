import waitForCsrfToken from '@/Functions/waitForCsrfToken';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productAndDishApi = createApi({
  reducerPath: 'productAndDishApi',
  tagTypes: ['ProductAndDish'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2/',
    prepareHeaders: async (headers, { getState }) => {
      const csrfToken = await waitForCsrfToken(getState);
      if (csrfToken) {
        headers.set('X-CSRF-TOKEN', csrfToken);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProductAndDishWithQuery: builder.query({
      query: (params) => ({
        url: route('menus.items.fetch'),
        method: 'GET',
        params: {
          'page[number]': params.page,
          'page[size]': params.size,
          'filter[name]':
            (params?.filters.name ?? '') !== ''
              ? params?.filters.name
              : undefined,
          'filter[dairyFree]':
            (params?.filters.productsAndDishesKind ?? '') === 'dairyFree'
              ? true
              : undefined,
          'filter[eggFree]':
            (params?.filters.productsAndDishesKind ?? '') === 'eggFree'
              ? true
              : undefined,
          'filter[glutenFree]':
            (params?.filters.productsAndDishesKind ?? '') === 'glutenFree'
              ? true
              : undefined,
          'filter[vegetarian]':
            (params?.filters.productsAndDishesKind ?? '') === 'vegetarian'
              ? true
              : undefined,
          'filter[nursery]':
            (params?.filters.productsAndDishesAgeRange ?? '') === 'nursery'
              ? true
              : undefined,
          'filter[kindergarten]':
            (params?.filters.productsAndDishesAgeRange ?? '') === 'kindergarten'
              ? true
              : undefined,
          'filter[primarySchool]':
            (params?.filters.productsAndDishesAgeRange ?? '') === 'primarySchool'
              ? true
              : undefined,
          'filter[highSchool]':
            (params?.filters.productsAndDishesAgeRange ?? '') === 'highSchool'
              ? true
              : undefined,
          'filter[adults]':
            (params?.filters.productsAndDishesAgeRange ?? '') === 'adults'
              ? true
              : undefined,
          'filter[breakfast]':
            (params?.filters.productsAndDishesCategory ?? '') === 'breakfast'
              ? true
              : undefined,
          'filter[secondBreakfast]':
            (params?.filters.productsAndDishesCategory ?? '') ===
            'secondBreakfast'
              ? true
              : undefined,
          'filter[soupDinner]':
            (params?.filters.productsAndDishesCategory ?? '') === 'soupDinner'
              ? true
              : undefined,
          'filter[secondCourseDinner]':
            (params?.filters.productsAndDishesCategory ?? '') ===
            'secondCourseDinner'
              ? true
              : undefined,
          'filter[afternoonTea]':
            (params?.filters.productsAndDishesCategory ?? '') === 'afternoonTea'
              ? true
              : undefined,
          'filter[supper]':
            (params?.filters.productsAndDishesCategory ?? '') === 'supper'
              ? true
              : undefined,
          'filter[drink]':
            (params?.filters.productsAndDishesCategory ?? '') === 'drink'
              ? true
              : undefined,
          'filter[dessert]':
            (params?.filters.productsAndDishesCategory ?? '') === 'dessert'
              ? true
              : undefined,
          'filter[macro_micro]':
            (params?.filters.productsAndDishesMacroAndMicronutrients ?? '') !==
            ''
              ? `${params?.filters.productsAndDishesMacroAndMicronutrients},${
                  (params?.filters.productsAndDishesMacroAndMicronutrientsRange
                    ?.from ?? '') !== undefined
                    ? params?.filters.productsAndDishesMacroAndMicronutrientsRange
                        ?.from ?? ''
                    : ''
                },${
                  (params?.filters.productsAndDishesMacroAndMicronutrientsRange
                    ?.to ?? '') !== undefined
                    ? params?.filters.productsAndDishesMacroAndMicronutrientsRange?.to ??
                      ''
                    : ''
                }`
              : undefined,
          'filter[database]': params?.filters?.database ?? undefined,
          'mode': params?.mode ?? 'BOTH'
        }
      }),
      providesTags: ['ProductAndDish'],
      keepUnusedDataFor: 300
    })
  })
});

export const { useGetProductAndDishWithQueryQuery } = productAndDishApi;
